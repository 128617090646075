import http from './http';

export const success_code = 20000;
export const failed_code = 40000;


//登录
export const getNewsInfo = (type, date) => {
    return http.requestGet('/topSearch/getNewsInfo?type='+type+'&date='+date);
};

//登录
export const addInfo = (vo) => {
    return http.requestPost('/portal/comment/addInfo',vo);
  };
  
  //登录
  export const addOperationLog = (vo) => {
    return http.requestPost('/portal/comment/addOperationLog',vo);
  };
