import { createApp } from 'vue'
import { Button, Tabbar, TabbarItem,Divider,BackTop,Calendar,Cell ,Field, CellGroup /* 其他需要使用的Vant组件 */ } from 'vant';
import 'vant/lib/index.css'; // 引入Vant的样式文件
import App from './App.vue'
// import { createRouter, createWebHistory } from 'vue-router';
// import HelloWorld from './components/HelloWorld.vue';
const app = createApp(App);

// 注册需要全局引入的Vant组件
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Divider);
app.use(BackTop);
app.use(Calendar);
app.use(Cell);
app.use(Field);
app.use(CellGroup);

// 创建路由对象并定义路由规则
// const router = createRouter({
//   history: createWebHistory(),
//   routes: [
//     // 这里定义了一个带有动态参数的路由规则
//     { path: '/', component: HelloWorld },
//   ],
// });
// app.use(router);

app.mount('#app')
