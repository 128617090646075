<template>
<div style="display: flex; align-items: center;">
  <!-- <van-cell title="" :value="date" @click="show = true" /> -->
  <van-cell-group inset style="width: 60%;">
  <van-field label="日期：" placeholder="请选择日期" :model-value="date"  @click="show = true" readonly/>
</van-cell-group>
  <van-button plain type="success" @click="show = true"  :text="date" style="flex: 1;">选择日期</van-button>
  <van-calendar v-model:show="show" @confirm="onConfirm" :min-date="minDate"/>
</div>
  <div class="hot-news" v-loading="loading">

    <h3>{{active == 1?"百度":"微博"}}热搜新闻{{ "(数量："+(active == 1?hotNewsList.length:weiboHotNews.length)+")" }}</h3>
    <!-- <ul>
      <li v-for="newsItem in hotNewsList" :key="newsItem.top">
        <a :href="newsItem.url" target="_blank">
        <p>{{ newsItem.top+"." + newsItem.title }}</p>
        <p>{{ newsItem.content }}</p>
        </a>
      
      </li>
    </ul> -->
<!-- 百度热搜 -->
    <div class="last-articles-box"  v-show="active==1">
        <div
          class="article-item default-border-radius clear-fix"
          v-for="(item, index) in hotNewsList"
          :key="index"
        >

        <a :href="item.url" target="_blank">
          <div class="article-left float-left">
            <div class="article-title">
              <span class="title">
                {{
                   item.top+"." + item.title +"(热度："+item.level+")"
                }}
              </span>
            </div>
            <div class="artice-img">
<img :src="item.img" />
        </div>
            <div class="article-summary">
              <p>
                {{ item.content }}
              </p>
            </div>

          </div>
        </a>
        </div>
  
      </div>
<!-- 微博热搜 -->
<div class="weibo-box" v-show="active == 2">
<div class="weibo-item" v-for="(item, index) in weiboHotNews" :key="index">
  <div class="weibo-item-left">
    <a :href="item.url" target="_blank">
   
    <div class="weibo-item-title">
      <span class="title">
        {{ item.top+". &nbsp;" + item.title  }}
      </span>
      <span class="level">
        {{ "(热度："+item.level+")" }}
      </span>
    </div>
  </a>
  <van-divider  right="15vw" bottom="500px"/>
  </div>
</div>
</div>
<van-back-top />
      <van-tabbar v-model="active"   @change="selectType" :placeholder="true">
  <van-tabbar-item icon="baidu" :name="1" >
    <span>百度热搜</span>
    <template #icon="props">
      <img :src="props.active ? icon.active : icon.inactive" />
    </template>
  </van-tabbar-item>

  <van-tabbar-item icon="weibo" :name="2">微博热搜</van-tabbar-item>

</van-tabbar>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue';
import { failed_code, success_code, getNewsInfo,addInfo,addOperationLog } from '../api/api.js';
import {formatDates} from '../utils/date.js'
    const hotNewsList = ref([]);
    const weiboHotNews = ref([]);
    const loading = ref(true);
    const active = ref(1);
    const minDate = new Date(2023, 6, 29);
    async function fetchHotNews(type,date) {
      loading.value = true;
     getNewsInfo(type,date).then((res) => {
      if (res.code === success_code) {
        if (type == 1) {
          hotNewsList.value = reactive(res.data);
        } else {
          weiboHotNews.value = reactive(res.data);
        }
 
      } else {
   
        if (type == 1) {
          hotNewsList.value = reactive([]);
        } else {
          weiboHotNews.value = reactive([]);
        }
      }
       loading.value = false;
     }).finally(() => {
      loading.value = false;
     });

     };

     function selectType(type) {
      console.log(type,'type',active.value);
        fetchHotNews(active.value,date.value);
  
     }
    // 模拟获取热搜新闻数据的函数，你需要替换成实际的数据获取逻辑

    function addIp(ip) {
      let vo = {
        ipAddress: ip,
      };
      addInfo(vo).then();
      // console.log(vo,'vo1');
      vo = {
        ipAddress: ip,
        remark: "查看热搜"
      };
      console.log(vo, "vo2");
      addOperationLog(vo).then();
    }

    const icon = {
      active: 'https://s1.chu0.com/src/img/png/27/27a11c94afa948a98f24dde4fc909e9a.png?imageMogr2/auto-orient/thumbnail/!240x240r/gravity/Center/crop/240x240/quality/85/&e=1735488000&token=1srnZGLKZ0Aqlz6dk7yF4SkiYf4eP-YrEOdM1sob:Z14XV8u-rMTr1mlmMM0BP9wMSsU=',
      inactive:
        'https://s1.chu0.com/src/img/png/19/19e7b77665514e3f807ac022124e8d48.png?imageMogr2/auto-orient/thumbnail/!240x240r/gravity/Center/crop/240x240/quality/85/&e=1735488000&token=1srnZGLKZ0Aqlz6dk7yF4SkiYf4eP-YrEOdM1sob:O2kqvxepYzo738tHS32I9IJXJRk=',
    };

    const date = ref(formatDates(new Date(), 'yyyy-MM-dd'));
    const show = ref(false);

   
    const onConfirm = (value) => {
      show.value = false;
      date.value = formatDates(value, 'yyyy-MM-dd');
      fetchHotNews(active.value,date.value);
    };
   
    // const type = this.$route.query.type
    // console.log(type.value,'type');
   
    onMounted(() => {
      // 组件挂载后获取热搜新闻数据
      const jinrishici = require("jinrishici");
    jinrishici.load((result) => {
      // this.contents = result.data.content;
      addIp(result.ipAddress);
      console.log('abcd--',result);
      // console.log('abcd--'+this.contents);
    });
      fetchHotNews(active.value,formatDates(new Date(), 'yyyy-MM-dd'));
       console.log(hotNewsList.value,'hotNewsList');
      
    });
   


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.artice-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3%;
  /* float: left; */
}



.article-summary {
  margin: 5px;
  /* margin-top: 5px; */
  font-size: 17px;
  text-align: justify;
  color: #909399;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 500px; */
}



.article-title {
  margin: 10px;
  text-align: justify;
  font-size: 18px;
}



/* .article-title .title a {
  font-size: 30px;
  color: #606266;
  vertical-align: middle;
  cursor: pointer;
} */

.article-item {
  background: #fff;
  margin-bottom: 5px;
  padding: 3px;
  border: 2px solid #d9d9db;
  border-radius: 5%;
  
}
.weibo-item {
  /* background: #d9d9db; */
  /* margin-bottom: 2px; */
  padding: 3px;
  /* border: 2px solid #d9d9db; */
  /* border-radius: 8%; */
  /* margin:10px */
}

.weibo-item-title {
  /* margin: 10px; */
  text-align: justify;
  font-size: 14px;
  margin-left: 10px;
  color: #000;
}
.level {
  font-size: 12px;
  color: #909399;
}
.article-item .weibo-item a{
  text-decoration: none;
}

.top-article-box {
  margin-top: 20px;
}



.article-info .user-name {
  font-weight: 500;
}

.article-info {
  margin-top: 20px;
}

.article-info span {
  font-size: 14px;
  line-height: 30px;
  color: #737f90;
}

.article-info img {
  margin-right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
}
</style>
